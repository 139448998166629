import { AppConfig, RouteType, SideBarItem } from "@/types";
import { Actor, hasRole } from "./authorization";

/**
 * Takes an App's config object, and evaluates all the supplied functions
 * for the sideBarItem's `icon` key. This allows for dynamic rendering of the
 * side bar icons.
 * @param appConfig Application-specific config, defined in appConfig.ts
 * @param iconParamsMap Map from side bar item key, to parameters passed into icon function
 * @returns An array of 'processed' sidebar items
 */
export const processSideBarItems = <R extends RouteType>(
  appConfig: AppConfig<R>,
  iconParamsMap: Record<string, unknown>,
  actor: Actor,
): SideBarItem<R>[] => {
  const sidebarItems: SideBarItem<R>[] = [];

  for (const [key, item] of Object.entries(appConfig.sideBarItems)) {
    const userCanAccess = hasRole({ ...actor, allowedRoles: item.allowedRoles ?? [] });

    sidebarItems.push({
      ...item,
      icon: item.icon(iconParamsMap[key]),
      disabled: !userCanAccess,
    });
  }

  return sidebarItems;
};

export const reloadPage = (): void => {
  window.location.reload();
};
