export const colors = {
  primaryBlue: "#3B7AE9",
  primaryDarkBlue: "#3549B7",
  primaryLightBlue: "#A3BCE8",
  lightBlue: "#d8e4fb",
  midGrey: "#9198A5",
  darkGrey: "#474D58",
  lightGrey: "#D9DDE4",
  extraDarkGrey: "#252930",
  neutralBlack: "#111112",
  error: "#CF1322",
  success: "#058C4B",
  warning: "#ffc069",
  mkPink: "#FFE5F6",
  mkPeach: "#FFE0DD",
  mkWhite: "#fff",
  mkLightBlue: "#f4f8ff",
  mkGreyBackground: "#f1f1f1",
  mkWarningRed: "#fea39a",
  mkSourceTagOutlineColor: "#FE7ED4",
  mkSourceTagTextcolor: "#D00082",
  mkSourceTagBackgroundColor: "#FFE5F6",
  mkSectorTagOutlineColor: "#FD6657",
  mkSectorTagTextcolor: "#E24636",
  warningIconColor: "#faad14",
  infoIconColor: "rgb(22,119,255)",
  successIconColor: "rgb(82,196,26)",
  errorIconColor: "rgb(255,76,78)",
  modalTitleColor: "rgba(0, 0, 0, 0.85)",
  successBackground: "#f6ffed",
  successBorder: "#b7eb8f",
  stoppedTagBorder: "#bfbfbf",
  stoppedTagBackground: "#f5f5f5",
  dataExplorerBorder: "#d9d9d9",
  dataExplorerBackground: "rgba(217, 221, 228, 0.30)",
  dataExplorerTabsShadow: "rgba(0, 0, 0, 0.25)",
  ruleBackgroundColor: "rgba(145, 152, 165, 0.2)",
  queryBuilderInvalidBackgroundColor: "rgba(102, 51, 153, 0.4)",
  ruleValuePlaceholder: "#47246b",
  ruleValuePlaceholderDarkTheme: "#945ec9",
  boxShadow12: "rgba(0, 0, 0, 0.12)",
  boxShadow8: "rgba(0, 0, 0, 0.08)",
  boxShadow5: "rgba(0, 0, 0, 0.05)",
};
