import { getAppConfig } from "@/utils/core/nextPublicConfigRuntime";
import * as Sentry from "@sentry/nextjs";
import { appConfigParams } from "utils/nextPublicConfigRuntime";

const sentryClientInit = (dsn: string): void =>
  Sentry.init({
    dsn: dsn,
    tracesSampleRate: 0.7,
  });

sentryClientInit(getAppConfig(appConfigParams).NEXT_PUBLIC_SENTRY_DSN);
