import { NextPublicConfigRuntime } from "@/types";

export interface AppConfigParams {
  owner: string;
  top_level_domain: string;
  dsn: string;
  env: "dev" | "ppd" | "uat" | "prod";
}

export const isClientSide = (): boolean => typeof window !== "undefined";

const constructApiUrl = ({ owner, top_level_domain, env }: Omit<AppConfigParams, "dsn">): string => {
  // Allow full API configuration when in local dev
  if (window.location.hostname === "localhost") {
    if (env === "prod") return `https://api.${owner}.${top_level_domain}`;

    return process.env.NEXT_PUBLIC_API_URL || `https://api.${env}.${owner}.${top_level_domain}`;
  }

  // Otherwise use the hostname provided by the deployed URL
  return `https://api.${window.location.hostname}`;
};

/**
 * Dynamically evaluates the client-side environment variables following the same construction rules as the terraform here: https://github.com/MaxKelsen/mk-propel-terraform/blob/main/modules/tenancy/cloud-run.tf
 * @param owner
 * @param top_level_domain
 * @param dsn
 * @returns
 */
export const getAppConfig = ({ owner, top_level_domain, dsn, env }: AppConfigParams): NextPublicConfigRuntime => {
  if (process.env.NEXT_PUBLIC_IS_E2E) {
    return {
      NEXT_PUBLIC_API_URL: "http://localhost:3000",
      NEXT_PUBLIC_SENTRY_DSN: "",
    };
  }

  const onClient = isClientSide();

  return {
    NEXT_PUBLIC_API_URL: onClient ? constructApiUrl({ owner, top_level_domain, env }) : process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_SENTRY_DSN: onClient ? dsn : process.env.NEXT_PUBLIC_SENTRY_DSN,
  };
};
