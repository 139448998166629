import {
  apiGenerated as api,
  DownloadDocumentApiV1DocumentsDocumentIdGetApiArg,
  DownloadDocumentApiV1DocumentsDocumentIdGetApiResponse,
  UserList,
} from "./apiGenerated";

// !IMPORTANT: invalidatesTags does not seem to be working correctly with SSR so we need to manually dispatch invalidate actions where any api calls where we expect tags to be invalidated are made

/* ---------------------------- Types and Consts ---------------------------- */
type TagName = (typeof tags)[number];
type Tag = { type: TagName; id?: string };
const listTag: Tag = { type: "User", id: "USER_LIST" } as const;
const tags = [
  "Me",
  "User",
  "Dataset",
  "Document",
  "Workbench",
  "Notebook",
  "AuditLog",
  "Query",
  "Cohort",
  "QueryExecution",
] as const;

/* -------------------------- Tag helper functions -------------------------- */

/**
 * Creates an item tagged user for each result where the id is the user id
 * Also creates an overall "list" tag
 * @param result
 * @returns
 */
export const usersResultToTags = (result?: UserList): Tag[] => {
  if (!result?.users) {
    return [listTag];
  }

  const userTags: Tag[] = result.users.map(user => ({ type: "User", id: user.id }));
  return [...userTags, listTag];
};

/**
 * Getter function for the tag for a specified user and the overall user list tag
 * @param userId
 * @returns
 */
export const getUserAndListTag = (userId: string): Tag[] => {
  const userTag: Tag = { type: "User", id: userId };
  return [userTag, listTag];
};

/**
 * Getter function for the user list tag
 * @returns
 */
export const getListTag = (): Tag[] => {
  return [listTag];
};

/* ------------------------- Api Endpoint Overrides ------------------------ */

api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    downloadDocumentApiV1DocumentsDocumentIdGet: build.query<
      DownloadDocumentApiV1DocumentsDocumentIdGetApiResponse,
      DownloadDocumentApiV1DocumentsDocumentIdGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/documents/${queryArg.documentId}/`,
        responseHandler: async response => {
          const resBlob = await response.blob();
          const blobUrl = window.URL.createObjectURL(resBlob);

          return { blobUrl };
        },
        cache: "no-cache",
      }),
    }),
  }),
});

/* ------------------------- Enhanced Api Definition ------------------------ */

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: tags,
  endpoints: {
    getLoggedInUserApiV1UsersMeGet: {
      providesTags: result => [{ type: "Me", id: result?.id }],
    },
    listAllUsersApiV1UsersGet: {
      providesTags: result => usersResultToTags(result),
    },
    logoutCurrentUserApiV1UsersMeLogoutPost: {
      invalidatesTags: result => [{ type: "Me", id: result?.id }],
    },
    createUsersApiV1UsersPost: {
      invalidatesTags: () => getListTag(),
    },
    getUserApiV1UsersUserIdGet: {
      providesTags: (result, error, { userId }) => [{ type: "User", id: userId }],
    },
    disableUserApiV1UsersDisableUserIdPost: {
      invalidatesTags: (result, error, { userId }) => getUserAndListTag(userId),
    },
    revokeInviteApiV1UsersRevokeUserIdPost: {
      invalidatesTags: (result, error, { userId }) => getUserAndListTag(userId),
    },
    updateInviteApiV1UsersInvitationUserIdUserEmailPost: {
      invalidatesTags: (result, error, { userId }) => getUserAndListTag(userId),
    },
    enableUserApiV1UsersEnableUserIdPost: {
      invalidatesTags: (result, error, { userId }) => getUserAndListTag(userId),
    },
    listWorkbenchesApiV1WorkbenchesGet: {
      providesTags: result =>
        result?.workbenches
          ? [...result.workbenches.map(({ id }) => ({ type: "Workbench" as const, id: id })), "Workbench"]
          : ["Workbench"],
    },
    uploadDocumentsApiV1DocumentsPost: {
      invalidatesTags: () => ["Document"],
    },
    downloadDocumentApiV1DocumentsDocumentIdGet: {
      providesTags: (result, error, { documentId }) => [{ type: "Document", id: documentId }],
    },
    deleteDocumentApiV1DocumentsDocumentIdDelete: {
      invalidatesTags: (result, error, { documentId }) => [{ type: "Document", id: documentId }],
    },
    createWorkbenchApiV1WorkbenchesPost: {
      invalidatesTags: () => ["Workbench"],
    },
    getWorkbenchApiV1WorkbenchesWorkbenchIdGet: {
      providesTags: (result, error, { workbenchId }) => [{ type: "Workbench", id: workbenchId }],
    },
    updateWorkbenchApiV1WorkbenchesWorkbenchIdPut: {
      invalidatesTags: (result, error, { workbenchId }) => [{ type: "Workbench", id: workbenchId }],
    },
    deleteWorkbenchApiV1WorkbenchesWorkbenchIdDelete: {
      invalidatesTags: (result, error, { workbenchId }) => [{ type: "Workbench", id: workbenchId }],
    },
    listAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGet: {
      providesTags: result =>
        result?.notebooks
          ? [...result.notebooks.map(({ id }) => ({ type: "Notebook" as const, id: id })), "Notebook"]
          : ["Notebook"],
    },
    startNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPost: {
      invalidatesTags: (result, error, { notebookId }) => [{ type: "Notebook", id: notebookId }],
    },
    stopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPost: {
      invalidatesTags: (result, error, { notebookId }) => [{ type: "Notebook", id: notebookId }],
    },
    createNotebookApiV1WorkbenchesWorkbenchIdNotebooksPost: {
      invalidatesTags: () => ["Notebook"],
    },
    deleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDelete: {
      invalidatesTags: (result, error, { notebookId }) => [{ type: "Notebook", id: notebookId }],
    },
    listAuditLogApiV1LogsGet: {
      providesTags: result =>
        result?.entries
          ? [...result.entries.map(({ message }) => ({ type: "AuditLog" as const, id: message })), "AuditLog"]
          : ["AuditLog"],
    },
    listNamedQueriesApiV1QueriesGet: {
      providesTags: result =>
        result?.queries
          ? [...result.queries.map(({ id }) => ({ type: "Query" as const, id: id })), "Query"]
          : ["Query"],
    },
    listPublishedCohortsApiV1CohortsGet: {
      providesTags: result =>
        result?.cohorts
          ? [...result.cohorts.map(({ id }) => ({ type: "Cohort" as const, id: id })), "Cohort"]
          : ["Cohort"],
    },
    deleteQueryApiV1QueriesQueryIdDelete: {
      invalidatesTags: (result, error, { queryId }) => [{ type: "Query", id: queryId }],
    },
    getDataExplorerSchemaApiV1DataExplorerSchemaGet: {
      providesTags: result =>
        result?.tableSchema
          ? [...result.tableSchema.map(({ name }) => ({ type: "Query" as const, id: name })), "Query"]
          : ["Query"],
    },
    createQueryApiV1QueriesPost: {
      invalidatesTags: () => ["Query"],
    },
    updateQueryApiV1QueriesQueryIdPatch: {
      invalidatesTags: (result, error, { queryId }) => [{ type: "Query", id: queryId }],
    },
    updateCohortApiV1CohortsCohortIdPatch: {
      invalidatesTags: (result, error, { cohortId }) => [{ type: "Cohort", id: cohortId }],
    },
    getLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGet: {
      providesTags: (result, error, { queryId }) => [{ type: "QueryExecution", id: queryId }],
    },
    createQueryExecutionApiV1QueriesQueryIdExecutionsPost: {
      invalidatesTags: (result, error, { queryId }) => [{ type: "QueryExecution", id: queryId }],
    },
    createAnonymousQueryExecutionApiV1QueriesExecutePost: {
      invalidatesTags: () => ["QueryExecution"],
    },
  },
});

export { enhancedApi as propelApi };

export const {
  useGetLoggedInUserApiV1UsersMeGetQuery,
  useLogoutCurrentUserApiV1UsersMeLogoutPostMutation,
  useListAllUsersApiV1UsersGetQuery,
  useCreateUsersApiV1UsersPostMutation,
  useGetUserApiV1UsersUserIdGetQuery,
  useDisableUserApiV1UsersDisableUserIdPostMutation,
  useRevokeInviteApiV1UsersRevokeUserIdPostMutation,
  useUpdateInviteApiV1UsersInvitationUserIdUserEmailPostMutation,
  useEnableUserApiV1UsersEnableUserIdPostMutation,
  useListWorkbenchesApiV1WorkbenchesGetQuery,
  useLazyListWorkbenchesApiV1WorkbenchesGetQuery,
  useUploadDocumentsApiV1DocumentsPostMutation,
  useLazyDownloadDocumentApiV1DocumentsDocumentIdGetQuery: useLazyDownloadDocumentQuery,
  useCreateWorkbenchApiV1WorkbenchesPostMutation,
  useDeleteDocumentApiV1DocumentsDocumentIdDeleteMutation,
  useGetWorkbenchApiV1WorkbenchesWorkbenchIdGetQuery,
  useGetWorkbenchNotebookZonesApiV1WorkbenchesWorkbenchIdZonesGetQuery: useGetWorkbenchNotebookZonesQuery,
  useGetWorkbenchEnvironmentsApiV1WorkbenchesWorkbenchIdEnvironmentsGetQuery: useGetWorkbenchEnvironmentsQuery,
  useGetWorkbenchMachineTypesApiV1WorkbenchesWorkbenchIdZoneZoneMachineTypesGetQuery: useGetWorkbenchMachineTypesQuery,
  useGetWorkbenchAcceleratorTypesApiV1WorkbenchesWorkbenchIdZoneZoneAcceleratorTypesGetQuery:
    useGetWorkbenchAcceleratorTypesQuery,
  useUpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutMutation,
  useDeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteMutation,
  useListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetQuery,
  useStartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostMutation,
  useStopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostMutation,
  useCreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostMutation: useCreateNotebookMutation,
  useDeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteMutation,
  useListAuditLogApiV1LogsGetQuery,
  useListNamedQueriesApiV1QueriesGetQuery,
  useListPublishedCohortsApiV1CohortsGetQuery,
  useDeleteQueryApiV1QueriesQueryIdDeleteMutation,
  useGetDataExplorerSchemaApiV1DataExplorerSchemaGetQuery,
  useCreateQueryApiV1QueriesPostMutation,
  useUpdateQueryApiV1QueriesQueryIdPatchMutation,
  useUpdateCohortApiV1CohortsCohortIdPatchMutation,
  useGetLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGetQuery: useGetLatestQueryExecution,
  useCreateQueryExecutionApiV1QueriesQueryIdExecutionsPostMutation: useCreateQueryExecutionMutation,
  useCreateAnonymousQueryExecutionApiV1QueriesExecutePostMutation: useCreateAnonymousQueryExecutionMutation,
  useGetVizzlyAccessTokensApiV1VizzlyTokensProjectIdGetQuery,
} = enhancedApi;
