import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { colors } from "@/styles/theme";
import { TooltipProps } from "antd/lib/tooltip";
import { endOfDay, formatISO, startOfDay, subDays, subMonths, subYears } from "date-fns";
import type { RuleGroupType } from "react-querybuilder";
import { AuditLogEventType } from "store/api/apiGenerated";

export const USER_LIST_LIMIT = 15;
export const WORKBENCH_DATA_LIST_PAGE_SIZE = 15;
export const WORKBENCH_LIST_LIMIT = 10;
export const DEFAULT_PAGE = "1";
export const DATASET_LIST_LIMIT = 10;
export const SAVED_QUERIES_LIST_LIMIT = 10;
export const DATASETS_LIST_LIMIT = 10;
export const PUBLISHED_COHORTS_LIST_LIMIT = 10;
export const WORKBENCH_DOCUMENTS_LIST_LIMIT = 10;
export const WORKBENCH_TAGS_LIMIT = 12;
export const NOTEBOOKS_LIST_LIMIT = 10;
export const AUDIT_LOG_LIST_LIMIT = 10;
export const AUDIT_LOG_DEFAULT_ORDER_BY = "TIMESTAMP_DESC";
export const DEFAULT_SKIP = 0;
export const DATE_FORMAT = "dd MMM yyyy";

export const DEFAULT_ZONE = "australia-southeast1-a";

export const DEFAULT_MACHINE_TYPE = "n1-standard-4";

export const DEFAULT_AUDIT_LOG_EVENT_TYPE: AuditLogEventType = "All";

export const workbenchStepLabels = [
  { title: "Description" },
  { title: "Add Data" },
  { title: "Add Users" },
  { title: "Review" },
];

export const editWorkbenchStepLabels = [
  { title: "Description" },
  { title: "Manage Data" },
  { title: "Manage Users" },
  { title: "Review" },
];

export const REVIEW_STEP_INDEX = 3;
// 20 MB in bytes
export const ATTACHMENTS_MAX_SIZE = 20000000;

export type PresetRangeValue = [Date, Date];

export const today: Date = new Date();

export const todayRange: PresetRangeValue = [today, today];
export const past7DaysRange: PresetRangeValue = [subDays(today, 7), today];
export const past30DaysRange: PresetRangeValue = [subDays(today, 30), today];
export const Past6MonthsRange: PresetRangeValue = [subMonths(today, 6), today];
export const pastYearRange: PresetRangeValue = [subYears(today, 1), today];
export const DEFAULT_DATES_INITIAL_STATE = {
  startDate: formatISO(startOfDay(past30DaysRange[0])),
  endDate: formatISO(endOfDay(past30DaysRange[1])),
};

export const ranges: {
  label: string;
  value: PresetRangeValue;
}[] = [
  { label: "Today", value: todayRange },
  { label: "Past 7 days", value: past7DaysRange },
  { label: "Past 30 days", value: past30DaysRange },
  { label: "Past 6 Months", value: Past6MonthsRange },
  { label: "Past Year", value: pastYearRange },
];

export const initialQuery: RuleGroupType = { combinator: "and", rules: [] };

export type ModalVariant = "info" | "warning" | "error" | "success";

export const variantIconMap: Record<ModalVariant, React.ReactNode> = {
  error: <CloseCircleFilled style={{ color: colors.errorIconColor }} />,
  info: <ExclamationCircleFilled style={{ color: colors.infoIconColor }} />,
  success: <CheckCircleFilled style={{ color: colors.successIconColor }} />,
  warning: <ExclamationCircleFilled style={{ color: colors.warningIconColor }} />,
};

export const PUBLISH_COHORT_NAME_MIN_LENGTH = 3;
export const PUBLISH_COHORT_NAME_MAX_LENGTH = 80;
export const PUBLISH_COHORT_DESCRIPTION_MIN_LENGTH = 20;
export const PUBLISH_COHORT_TAG_MAX_COUNT = 3;
export const PUBLISH_COHORT_TAG_MIN_LENGTH = 2;
export const PUBLISH_COHORT_TAG_MAX_LENGTH = 30;
export const HELP_DESK =
  "https://savisurgical.atlassian.net/servicedesk/customer/portal/1/topic/e470bf32-eec7-4a68-8061-4658586666f7/article/5016453164";

export const ANT_TAG_MAX_WIDTH = "150px";
export const ANT_MOUSE_ENTER_DELAY = 0.6;
export const ANT_TYPOGRAPHY_PARAGRAPH_ELLIPSIS_ROWS = 3;
export const overrideTypographyEllipsisDefaults: Partial<TooltipProps> = {
  mouseEnterDelay: ANT_MOUSE_ENTER_DELAY,
  destroyTooltipOnHide: true,
  overlayStyle: { maxWidth: "50%" },
};
