import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    listAuditLogApiV1LogsGet: build.query<ListAuditLogApiV1LogsGetApiResponse, ListAuditLogApiV1LogsGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/logs/`,
        params: {
          search: queryArg.search,
          event_type: queryArg.eventType,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          order_by: queryArg.orderBy,
          skip: queryArg.skip,
          limit: queryArg.limit,
        },
      }),
    }),
    listPublishedCohortsApiV1CohortsGet: build.query<
      ListPublishedCohortsApiV1CohortsGetApiResponse,
      ListPublishedCohortsApiV1CohortsGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/cohorts/`,
        params: {
          skip: queryArg.skip,
          limit: queryArg.limit,
          search: queryArg.search,
          status: queryArg.status,
          order_by: queryArg.orderBy,
        },
      }),
    }),
    updateCohortApiV1CohortsCohortIdPatch: build.mutation<
      UpdateCohortApiV1CohortsCohortIdPatchApiResponse,
      UpdateCohortApiV1CohortsCohortIdPatchApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/cohorts/${queryArg.cohortId}/`,
        method: "PATCH",
        body: queryArg.cohortUpdate,
      }),
    }),
    getDataExplorerSchemaApiV1DataExplorerSchemaGet: build.query<
      GetDataExplorerSchemaApiV1DataExplorerSchemaGetApiResponse,
      GetDataExplorerSchemaApiV1DataExplorerSchemaGetApiArg
    >({
      query: () => ({ url: `/api/v1/data-explorer/schema/` }),
    }),
    uploadDocumentsApiV1DocumentsPost: build.mutation<
      UploadDocumentsApiV1DocumentsPostApiResponse,
      UploadDocumentsApiV1DocumentsPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/documents/`,
        method: "POST",
        body: queryArg.bodyUploadDocumentsApiV1DocumentsPost,
      }),
    }),
    downloadDocumentApiV1DocumentsDocumentIdGet: build.query<
      DownloadDocumentApiV1DocumentsDocumentIdGetApiResponse,
      DownloadDocumentApiV1DocumentsDocumentIdGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/documents/${queryArg.documentId}/` }),
    }),
    deleteDocumentApiV1DocumentsDocumentIdDelete: build.mutation<
      DeleteDocumentApiV1DocumentsDocumentIdDeleteApiResponse,
      DeleteDocumentApiV1DocumentsDocumentIdDeleteApiArg
    >({
      query: queryArg => ({ url: `/api/v1/documents/${queryArg.documentId}/`, method: "DELETE" }),
    }),
    deleteQueryApiV1QueriesQueryIdDelete: build.mutation<
      DeleteQueryApiV1QueriesQueryIdDeleteApiResponse,
      DeleteQueryApiV1QueriesQueryIdDeleteApiArg
    >({
      query: queryArg => ({ url: `/api/v1/queries/${queryArg.queryId}/`, method: "DELETE" }),
    }),
    updateQueryApiV1QueriesQueryIdPatch: build.mutation<
      UpdateQueryApiV1QueriesQueryIdPatchApiResponse,
      UpdateQueryApiV1QueriesQueryIdPatchApiArg
    >({
      query: queryArg => ({ url: `/api/v1/queries/${queryArg.queryId}/`, method: "PATCH", body: queryArg.queryUpdate }),
    }),
    listNamedQueriesApiV1QueriesGet: build.query<
      ListNamedQueriesApiV1QueriesGetApiResponse,
      ListNamedQueriesApiV1QueriesGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/queries/`,
        params: {
          name_search: queryArg.nameSearch,
          id_search: queryArg.idSearch,
          email_search: queryArg.emailSearch,
          created_by: queryArg.createdBy,
          skip: queryArg.skip,
          order_by: queryArg.orderBy,
          limit: queryArg.limit,
        },
      }),
    }),
    createQueryApiV1QueriesPost: build.mutation<
      CreateQueryApiV1QueriesPostApiResponse,
      CreateQueryApiV1QueriesPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/queries/`, method: "POST", body: queryArg.queryCreate }),
    }),
    getLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGet: build.query<
      GetLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGetApiResponse,
      GetLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/queries/${queryArg.queryId}/executions/latest/` }),
    }),
    getQueryExecutionApiV1QueriesQueryIdExecutionsQueryExecutionIdGet: build.query<
      GetQueryExecutionApiV1QueriesQueryIdExecutionsQueryExecutionIdGetApiResponse,
      GetQueryExecutionApiV1QueriesQueryIdExecutionsQueryExecutionIdGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/queries/${queryArg.queryId}/executions/${queryArg.queryExecutionId}/` }),
    }),
    createAnonymousQueryExecutionApiV1QueriesExecutePost: build.mutation<
      CreateAnonymousQueryExecutionApiV1QueriesExecutePostApiResponse,
      CreateAnonymousQueryExecutionApiV1QueriesExecutePostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/queries/execute/`,
        method: "POST",
        body: queryArg.anonymousQueryExecutionCreate,
      }),
    }),
    createQueryExecutionApiV1QueriesQueryIdExecutionsPost: build.mutation<
      CreateQueryExecutionApiV1QueriesQueryIdExecutionsPostApiResponse,
      CreateQueryExecutionApiV1QueriesQueryIdExecutionsPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/queries/${queryArg.queryId}/executions/`, method: "POST" }),
    }),
    listAllUsersApiV1UsersGet: build.query<ListAllUsersApiV1UsersGetApiResponse, ListAllUsersApiV1UsersGetApiArg>({
      query: queryArg => ({
        url: `/api/v1/users/`,
        params: {
          skip: queryArg.skip,
          limit: queryArg.limit,
          search: queryArg.search,
          order_by: queryArg.orderBy,
          user_source: queryArg.userSource,
          status: queryArg.status,
          roles: queryArg.roles,
        },
      }),
    }),
    createUsersApiV1UsersPost: build.mutation<CreateUsersApiV1UsersPostApiResponse, CreateUsersApiV1UsersPostApiArg>({
      query: queryArg => ({ url: `/api/v1/users/`, method: "POST", body: queryArg.request }),
    }),
    getLoggedInUserApiV1UsersMeGet: build.query<
      GetLoggedInUserApiV1UsersMeGetApiResponse,
      GetLoggedInUserApiV1UsersMeGetApiArg
    >({
      query: () => ({ url: `/api/v1/users/me/` }),
    }),
    logoutCurrentUserApiV1UsersMeLogoutPost: build.mutation<
      LogoutCurrentUserApiV1UsersMeLogoutPostApiResponse,
      LogoutCurrentUserApiV1UsersMeLogoutPostApiArg
    >({
      query: () => ({ url: `/api/v1/users/me/logout/`, method: "POST" }),
    }),
    getUserApiV1UsersUserIdGet: build.query<GetUserApiV1UsersUserIdGetApiResponse, GetUserApiV1UsersUserIdGetApiArg>({
      query: queryArg => ({ url: `/api/v1/users/${queryArg.userId}/` }),
    }),
    updateInviteApiV1UsersInvitationUserIdUserEmailPost: build.mutation<
      UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiResponse,
      UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/users/invitation/${queryArg.userId}/${queryArg.userEmail}/`,
        method: "POST",
      }),
    }),
    disableUserApiV1UsersDisableUserIdPost: build.mutation<
      DisableUserApiV1UsersDisableUserIdPostApiResponse,
      DisableUserApiV1UsersDisableUserIdPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/users/disable/${queryArg.userId}/`, method: "POST" }),
    }),
    enableUserApiV1UsersEnableUserIdPost: build.mutation<
      EnableUserApiV1UsersEnableUserIdPostApiResponse,
      EnableUserApiV1UsersEnableUserIdPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/users/enable/${queryArg.userId}/`, method: "POST" }),
    }),
    revokeInviteApiV1UsersRevokeUserIdPost: build.mutation<
      RevokeInviteApiV1UsersRevokeUserIdPostApiResponse,
      RevokeInviteApiV1UsersRevokeUserIdPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/users/revoke/${queryArg.userId}/`, method: "POST" }),
    }),
    getVizzlyAccessTokensApiV1VizzlyTokensProjectIdGet: build.query<
      GetVizzlyAccessTokensApiV1VizzlyTokensProjectIdGetApiResponse,
      GetVizzlyAccessTokensApiV1VizzlyTokensProjectIdGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/vizzly/tokens/${queryArg.projectId}/` }),
    }),
    listWorkbenchesApiV1WorkbenchesGet: build.query<
      ListWorkbenchesApiV1WorkbenchesGetApiResponse,
      ListWorkbenchesApiV1WorkbenchesGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/`,
        params: { skip: queryArg.skip, limit: queryArg.limit, search: queryArg.search },
      }),
    }),
    createWorkbenchApiV1WorkbenchesPost: build.mutation<
      CreateWorkbenchApiV1WorkbenchesPostApiResponse,
      CreateWorkbenchApiV1WorkbenchesPostApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/`, method: "POST", body: queryArg.workbenchCreate }),
    }),
    getWorkbenchApiV1WorkbenchesWorkbenchIdGet: build.query<
      GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiResponse,
      GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/${queryArg.workbenchId}/` }),
    }),
    updateWorkbenchApiV1WorkbenchesWorkbenchIdPut: build.mutation<
      UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiResponse,
      UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/`,
        method: "PUT",
        body: queryArg.workbenchUpdate,
      }),
    }),
    deleteWorkbenchApiV1WorkbenchesWorkbenchIdDelete: build.mutation<
      DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiResponse,
      DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/${queryArg.workbenchId}/`, method: "DELETE" }),
    }),
    getWorkbenchNotebookZonesApiV1WorkbenchesWorkbenchIdZonesGet: build.query<
      GetWorkbenchNotebookZonesApiV1WorkbenchesWorkbenchIdZonesGetApiResponse,
      GetWorkbenchNotebookZonesApiV1WorkbenchesWorkbenchIdZonesGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/${queryArg.workbenchId}/zones/` }),
    }),
    getWorkbenchMachineTypesApiV1WorkbenchesWorkbenchIdZoneZoneMachineTypesGet: build.query<
      GetWorkbenchMachineTypesApiV1WorkbenchesWorkbenchIdZoneZoneMachineTypesGetApiResponse,
      GetWorkbenchMachineTypesApiV1WorkbenchesWorkbenchIdZoneZoneMachineTypesGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/${queryArg.workbenchId}/zone/${queryArg.zone}/machine-types/` }),
    }),
    getWorkbenchAcceleratorTypesApiV1WorkbenchesWorkbenchIdZoneZoneAcceleratorTypesGet: build.query<
      GetWorkbenchAcceleratorTypesApiV1WorkbenchesWorkbenchIdZoneZoneAcceleratorTypesGetApiResponse,
      GetWorkbenchAcceleratorTypesApiV1WorkbenchesWorkbenchIdZoneZoneAcceleratorTypesGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/zone/${queryArg.zone}/accelerator-types/`,
      }),
    }),
    getWorkbenchEnvironmentsApiV1WorkbenchesWorkbenchIdEnvironmentsGet: build.query<
      GetWorkbenchEnvironmentsApiV1WorkbenchesWorkbenchIdEnvironmentsGetApiResponse,
      GetWorkbenchEnvironmentsApiV1WorkbenchesWorkbenchIdEnvironmentsGetApiArg
    >({
      query: queryArg => ({ url: `/api/v1/workbenches/${queryArg.workbenchId}/environments/` }),
    }),
    deleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDelete: build.mutation<
      DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiResponse,
      DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/${queryArg.notebookId}/`,
        method: "DELETE",
      }),
    }),
    listAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGet: build.query<
      ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiResponse,
      ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/`,
        params: { skip: queryArg.skip, limit: queryArg.limit, search: queryArg.search, order_by: queryArg.orderBy },
      }),
    }),
    createNotebookApiV1WorkbenchesWorkbenchIdNotebooksPost: build.mutation<
      CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiResponse,
      CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/`,
        method: "POST",
        body: queryArg.notebookCreate,
      }),
    }),
    startNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPost: build.mutation<
      StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiResponse,
      StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/${queryArg.notebookId}/start/`,
        method: "POST",
      }),
    }),
    stopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPost: build.mutation<
      StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiResponse,
      StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiArg
    >({
      query: queryArg => ({
        url: `/api/v1/workbenches/${queryArg.workbenchId}/notebooks/${queryArg.notebookId}/stop/`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as apiGenerated };
export type ListAuditLogApiV1LogsGetApiResponse = /** status 200 Successful Response */ AuditLogEntryList;
export type ListAuditLogApiV1LogsGetApiArg = {
  search?: string;
  eventType?: AuditLogEventType;
  startDate?: string;
  endDate?: string;
  orderBy?: AuditLogOrderBy;
  skip?: number;
  limit?: number;
};
export type ListPublishedCohortsApiV1CohortsGetApiResponse = /** status 200 Successful Response */ CohortList;
export type ListPublishedCohortsApiV1CohortsGetApiArg = {
  skip?: number;
  limit?: number;
  search?: string;
  status?: CohortStatus[];
  orderBy?: CohortOrderBy;
};
export type UpdateCohortApiV1CohortsCohortIdPatchApiResponse = /** status 200 Successful Response */ Cohort;
export type UpdateCohortApiV1CohortsCohortIdPatchApiArg = {
  cohortId: string;
  cohortUpdate: CohortUpdate;
};
export type GetDataExplorerSchemaApiV1DataExplorerSchemaGetApiResponse =
  /** status 200 Successful Response */ DatasetTableSchema;
export type GetDataExplorerSchemaApiV1DataExplorerSchemaGetApiArg = void;
export type UploadDocumentsApiV1DocumentsPostApiResponse = /** status 200 Successful Response */ DocumentUploadReturn;
export type UploadDocumentsApiV1DocumentsPostApiArg = {
  bodyUploadDocumentsApiV1DocumentsPost: BodyUploadDocumentsApiV1DocumentsPost;
};
export type DownloadDocumentApiV1DocumentsDocumentIdGetApiResponse = {
  blobUrl: string;
};
export type DownloadDocumentApiV1DocumentsDocumentIdGetApiArg = {
  documentId: string;
};
export type DeleteDocumentApiV1DocumentsDocumentIdDeleteApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type DeleteDocumentApiV1DocumentsDocumentIdDeleteApiArg = {
  documentId: string;
};
export type DeleteQueryApiV1QueriesQueryIdDeleteApiResponse = /** status 204 Successful Response */ void;
export type DeleteQueryApiV1QueriesQueryIdDeleteApiArg = {
  queryId: string;
};
export type UpdateQueryApiV1QueriesQueryIdPatchApiResponse = /** status 200 Successful Response */ Query;
export type UpdateQueryApiV1QueriesQueryIdPatchApiArg = {
  queryId: string;
  queryUpdate: QueryUpdate;
};
export type ListNamedQueriesApiV1QueriesGetApiResponse = /** status 200 Successful Response */ QueryList;
export type ListNamedQueriesApiV1QueriesGetApiArg = {
  nameSearch?: string;
  idSearch?: string;
  emailSearch?: string;
  createdBy?: QueryCreatedBy;
  skip?: number;
  orderBy?: QueryOrderBy;
  limit?: number;
};
export type CreateQueryApiV1QueriesPostApiResponse = /** status 200 Successful Response */ Query;
export type CreateQueryApiV1QueriesPostApiArg = {
  queryCreate: QueryCreate;
};
export type GetLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGetApiResponse =
  /** status 200 Successful Response */ QueryExecution;
export type GetLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGetApiArg = {
  queryId: string;
};
export type GetQueryExecutionApiV1QueriesQueryIdExecutionsQueryExecutionIdGetApiResponse =
  /** status 200 Successful Response */ QueryExecution;
export type GetQueryExecutionApiV1QueriesQueryIdExecutionsQueryExecutionIdGetApiArg = {
  queryExecutionId: string;
  queryId: string;
};
export type CreateAnonymousQueryExecutionApiV1QueriesExecutePostApiResponse =
  /** status 200 Successful Response */ QueryExecution;
export type CreateAnonymousQueryExecutionApiV1QueriesExecutePostApiArg = {
  anonymousQueryExecutionCreate: AnonymousQueryExecutionCreate;
};
export type CreateQueryExecutionApiV1QueriesQueryIdExecutionsPostApiResponse =
  /** status 200 Successful Response */ QueryExecution;
export type CreateQueryExecutionApiV1QueriesQueryIdExecutionsPostApiArg = {
  queryId: string;
};
export type ListAllUsersApiV1UsersGetApiResponse = /** status 200 Successful Response */ UserList;
export type ListAllUsersApiV1UsersGetApiArg = {
  skip?: number;
  limit?: number;
  search?: string;
  orderBy?: UserOrderBy;
  userSource?: Auth0Source[];
  status?: UserStatus[];
  roles?: TenantRole[];
};
export type CreateUsersApiV1UsersPostApiResponse = /** status 200 Successful Response */ UserCreateReturn;
export type CreateUsersApiV1UsersPostApiArg = {
  request: string[];
};
export type GetLoggedInUserApiV1UsersMeGetApiResponse = /** status 200 Successful Response */ UserWithRoles;
export type GetLoggedInUserApiV1UsersMeGetApiArg = void;
export type LogoutCurrentUserApiV1UsersMeLogoutPostApiResponse = /** status 200 Successful Response */ any;
export type LogoutCurrentUserApiV1UsersMeLogoutPostApiArg = void;
export type GetUserApiV1UsersUserIdGetApiResponse = /** status 200 Successful Response */ UserWithRoles;
export type GetUserApiV1UsersUserIdGetApiArg = {
  userId: string;
};
export type UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type UpdateInviteApiV1UsersInvitationUserIdUserEmailPostApiArg = {
  userId: string;
  userEmail: string;
};
export type DisableUserApiV1UsersDisableUserIdPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type DisableUserApiV1UsersDisableUserIdPostApiArg = {
  userId: string;
};
export type EnableUserApiV1UsersEnableUserIdPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type EnableUserApiV1UsersEnableUserIdPostApiArg = {
  userId: string;
};
export type RevokeInviteApiV1UsersRevokeUserIdPostApiResponse = /** status 200 Successful Response */ {
  [key: string]: string;
};
export type RevokeInviteApiV1UsersRevokeUserIdPostApiArg = {
  userId: string;
};
export type GetVizzlyAccessTokensApiV1VizzlyTokensProjectIdGetApiResponse =
  /** status 200 Successful Response */ VizzlyAccessTokens;
export type GetVizzlyAccessTokensApiV1VizzlyTokensProjectIdGetApiArg = {
  projectId: string;
};
export type ListWorkbenchesApiV1WorkbenchesGetApiResponse = /** status 200 Successful Response */ WorkbenchList;
export type ListWorkbenchesApiV1WorkbenchesGetApiArg = {
  skip?: number;
  limit?: number;
  search?: string;
};
export type CreateWorkbenchApiV1WorkbenchesPostApiResponse = /** status 200 Successful Response */ Workbench;
export type CreateWorkbenchApiV1WorkbenchesPostApiArg = {
  workbenchCreate: WorkbenchCreate;
};
export type GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiResponse = /** status 200 Successful Response */ Workbench;
export type GetWorkbenchApiV1WorkbenchesWorkbenchIdGetApiArg = {
  workbenchId: string;
};
export type UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiResponse = /** status 200 Successful Response */ Workbench;
export type UpdateWorkbenchApiV1WorkbenchesWorkbenchIdPutApiArg = {
  workbenchId: string;
  workbenchUpdate: WorkbenchUpdate;
};
export type DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiResponse = /** status 204 Successful Response */ void;
export type DeleteWorkbenchApiV1WorkbenchesWorkbenchIdDeleteApiArg = {
  workbenchId: string;
};
export type GetWorkbenchNotebookZonesApiV1WorkbenchesWorkbenchIdZonesGetApiResponse =
  /** status 200 Successful Response */ WorkbenchZonesResponse;
export type GetWorkbenchNotebookZonesApiV1WorkbenchesWorkbenchIdZonesGetApiArg = {
  workbenchId: string;
};
export type GetWorkbenchMachineTypesApiV1WorkbenchesWorkbenchIdZoneZoneMachineTypesGetApiResponse =
  /** status 200 Successful Response */ WorkbenchZoneMachineTypesResponse;
export type GetWorkbenchMachineTypesApiV1WorkbenchesWorkbenchIdZoneZoneMachineTypesGetApiArg = {
  zone: string;
  workbenchId: string;
};
export type GetWorkbenchAcceleratorTypesApiV1WorkbenchesWorkbenchIdZoneZoneAcceleratorTypesGetApiResponse =
  /** status 200 Successful Response */ WorkbenchZoneAcceleratorTypesResponse;
export type GetWorkbenchAcceleratorTypesApiV1WorkbenchesWorkbenchIdZoneZoneAcceleratorTypesGetApiArg = {
  zone: string;
  workbenchId: string;
};
export type GetWorkbenchEnvironmentsApiV1WorkbenchesWorkbenchIdEnvironmentsGetApiResponse =
  /** status 200 Successful Response */ WorkbenchEnvironmentResponse;
export type GetWorkbenchEnvironmentsApiV1WorkbenchesWorkbenchIdEnvironmentsGetApiArg = {
  workbenchId: string;
};
export type DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdDeleteApiArg = {
  notebookId: string;
  workbenchId: string;
};
export type ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiResponse =
  /** status 200 Successful Response */ NotebookList;
export type ListAllNotebooksApiV1WorkbenchesWorkbenchIdNotebooksGetApiArg = {
  workbenchId: string;
  skip?: number;
  limit?: number;
  search?: string;
  orderBy?: NotebookOrderBy;
};
export type CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiResponse =
  /** status 201 Successful Response */ Notebook;
export type CreateNotebookApiV1WorkbenchesWorkbenchIdNotebooksPostApiArg = {
  workbenchId: string;
  notebookCreate: NotebookCreate;
};
export type StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiResponse =
  /** status 200 Successful Response */ Notebook;
export type StartNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStartPostApiArg = {
  notebookId: string;
  workbenchId: string;
};
export type StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiResponse =
  /** status 200 Successful Response */ Notebook;
export type StopNotebookApiV1WorkbenchesWorkbenchIdNotebooksNotebookIdStopPostApiArg = {
  notebookId: string;
  workbenchId: string;
};
export type AuditLogEntry = {
  timestamp: string;
  message: string;
};
export type AuditLogEntryList = {
  entries: AuditLogEntry[];
  pageCount: number;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type AuditLogEventType =
  | "All"
  | "User Logged In"
  | "User Logged Out"
  | "User Invited"
  | "Invitation Accepted"
  | "User Activated"
  | "User Deactivated"
  | "Cohort Published"
  | "Cohort Created"
  | "Cohort Create Failed"
  | "Cohort Deleted"
  | "Workbench Created"
  | "Workbench Edited"
  | "Workbench Deleted"
  | "Workbench Accessed"
  | "Notebook Accessed"
  | "Used Consent Revoked"
  | "User Consent Updated"
  | "New Data Ingested"
  | "Data Removed";
export type AuditLogOrderBy = "TIMESTAMP_ASC" | "TIMESTAMP_DESC";
export type CohortStatus = "PENDING" | "EXECUTING" | "FINISHED" | "ERROR" | "RETIRED";
export type Cohort = {
  id: string;
  updated: string;
  name: string;
  description?: string;
  queryExecutionId: string;
  status: CohortStatus;
  tags?: string[];
  published?: boolean;
  deleted?: boolean;
  patientCount?: number;
  query: string;
};
export type CohortList = {
  cohorts: Cohort[];
  pageCount: number;
};
export type CohortOrderBy = "NAME_ASC" | "NAME_DESC" | "CREATED_ASC" | "CREATED_DESC" | "UPDATED_ASC" | "UPDATED_DESC";
export type CohortUpdate = {
  queryId?: string;
  name?: string;
  description?: string;
  tags?: string[];
  published?: boolean;
};
export type DatasetTableSchemaField = {
  name: string;
  type: string;
  description?: string;
};
export type DatasetTableSchema = {
  datasetId: string;
  tableId: string;
  tableSchema: DatasetTableSchemaField[];
};
export type DocumentUploadSuccess = {
  id: string;
  filename: string;
  created: string;
  href: string;
};
export type DocumentUploadFailure = {
  filename: string;
  message: string;
};
export type DocumentUploadReturn = {
  successes: DocumentUploadSuccess[];
  failures: DocumentUploadFailure[];
};
export type BodyUploadDocumentsApiV1DocumentsPost = {
  files: Blob[];
};
export type JsonLogicVariableReference = {
  var: string;
};
export type JsonLogic = {
  [key: string]:
    | (JsonLogicVariableReference | number | number | boolean | string | (number | number | boolean | string)[])[]
    | JsonLogic[]
    | JsonLogic;
};
export type Query = {
  id: string;
  content: JsonLogic;
  consent: string;
  name?: string;
  updated: string;
  createdById: string;
  createdByEmail: string;
};
export type QueryUpdate = {
  name?: string;
  content?: JsonLogic;
  consent?: string;
};
export type QueryList = {
  queries: Query[];
  pageCount: number;
};
export type QueryCreatedBy = "SELF" | "ANY";
export type QueryOrderBy = "NAME_ASC" | "NAME_DESC" | "CREATED_ASC" | "CREATED_DESC" | "UPDATED_ASC" | "UPDATED_DESC";
export type QueryCreate = {
  name?: string;
  content: JsonLogic;
  consent: string;
};
export type QueryExecutionStatus = "PENDING" | "EXECUTING" | "FINISHED" | "ERROR";
export type GenderAgeStatistics = {
  male: {
    [key: string]: number;
  };
  female: {
    [key: string]: number;
  };
  other?: {
    [key: string]: number;
  };
  unknown?: {
    [key: string]: number;
  };
};
export type QueryExecutionSummary = {
  totalPatientCount?: number;
  patientCount?: number;
  ageStatistics?: GenderAgeStatistics;
  fileStatistics?: {
    [key: string]: number;
  };
};
export type QueryExecution = {
  id: string;
  queryId: string;
  workflowId?: string;
  status: QueryExecutionStatus;
  completedAt?: string;
  content: string;
  summaryResult?: QueryExecutionSummary;
};
export type AnonymousQueryExecutionCreate = {
  content: JsonLogic;
  consent: string;
};
export type UserStatus = "PENDING" | "ACTIVE" | "DISABLED" | "DELETED";
export type TenantRole = "TENANT_ADMIN" | "TENANT_MEMBER";
export type Auth0Source = "Auth0" | "Azure AD";
export type UserWithRoles = {
  id: string;
  email: string;
  status: UserStatus;
  tenantRoles?: TenantRole[];
  authProviderId?: string;
  invitationId?: string;
  auth0Source?: Auth0Source;
  editable?: boolean;
};
export type UserList = {
  users: UserWithRoles[];
  pageCount: number;
};
export type UserOrderBy = "EMAIL_ASC" | "EMAIL_DESC" | "STATUS_ASC" | "STATUS_DESC";
export type UserCreateReturn = {
  createdUsers: UserWithRoles[];
  existingUsers: UserWithRoles[];
  disabledUsers: UserWithRoles[];
};
export type VizzlyAccessTokens = {
  dashboardAccessToken: string;
  dataAccessToken: string;
  queryEngineAccessToken: string;
};
export type WorkbenchStatus =
  | "PENDING"
  | "PROVISIONING"
  | "FAILED_TO_PROVISION"
  | "ACTIVE"
  | "RETIRED"
  | "FAILED_TO_DELETE";
export type WorkbenchRole = "WORKBENCH_ADMIN" | "WORKBENCH_MEMBER";
export type WorkbenchListItem = {
  name: string;
  description: string;
  id: string;
  status: WorkbenchStatus;
  projectId: string;
  userRoles?: WorkbenchRole[];
  version: number;
};
export type WorkbenchList = {
  workbenches: WorkbenchListItem[];
  pageCount: number;
};
export type ReviewStatus = "PENDING" | "APPROVED" | "REJECTED";
export type WorkbenchUser = {
  reviewedAt?: string;
  reviewMessage?: string;
  reviewedById?: string;
  reviewStatus: ReviewStatus;
  id: string;
  email: string;
  status: UserStatus;
  tenantRoles?: TenantRole[];
  authProviderId?: string;
  invitationId?: string;
  auth0Source?: Auth0Source;
  editable?: boolean;
  workbenchRoles: WorkbenchRole[];
};
export type Workbench = {
  name: string;
  description: string;
  id: string;
  status: WorkbenchStatus;
  projectId: string;
  version: number;
  versionId: string;
  activeVersionId: string;
  users?: WorkbenchUser[];
  documents?: DocumentUploadSuccess[];
  cohorts?: Cohort[];
};
export type WorkbenchCreate = {
  name: string;
  description: string;
  users?: string[];
  cohorts?: string[];
  queries?: string[];
  documents?: string[];
};
export type WorkbenchUpdate = {
  fromVersion: string;
  name?: string;
  description?: string;
  users?: string[];
  cohorts?: string[];
  queries?: string[];
  documents?: string[];
};
export type WorkbenchZonesResponse = {
  zones: string[];
};
export type NotebookMachineTypeListItem = {
  machineType: string;
  description: string;
};
export type WorkbenchZoneMachineTypesResponse = {
  machineTypes: NotebookMachineTypeListItem[];
};
export type NotebookAcceleratorType =
  | "ACCELERATOR_TYPE_UNSPECIFIED"
  | "NVIDIA_TESLA_K80"
  | "NVIDIA_TESLA_P100"
  | "NVIDIA_TESLA_V100"
  | "NVIDIA_TESLA_P4"
  | "NVIDIA_TESLA_T4"
  | "TPU_V2"
  | "TPU_V3"
  | "NVIDIA_TESLA_T4_VWS"
  | "NVIDIA_TESLA_P100_VWS"
  | "NVIDIA_TESLA_P4_VWS"
  | "NVIDIA_TESLA_A100";
export type WorkbenchZoneAcceleratorTypesResponse = {
  acceleratorTypes: NotebookAcceleratorType[];
};
export type NotebookEnvironment = {
  imageName: string;
  projectId: string;
  description: string;
};
export type WorkbenchEnvironmentResponse = {
  environments: NotebookEnvironment[];
};
export type NotebookState = "PROVISIONING" | "STARTING" | "ACTIVE" | "STOPPING" | "STOPPED" | "ERROR" | "DELETED";
export type Notebook = {
  id: string;
  name: string;
  workbenchId: string;
  location: string;
  createdById: string;
  updated: string;
  created: string;
  createdByEmail: string;
  state: NotebookState;
  machineType: string;
  gpuType: NotebookAcceleratorType;
  proxyUri: string;
};
export type NotebookList = {
  notebooks: Notebook[];
  pageCount: number;
};
export type NotebookOrderBy =
  | "NAME_ASC"
  | "NAME_DESC"
  | "CREATED_BY_EMAIL_ASC"
  | "CREATED_BY_EMAIL_DESC"
  | "UPDATED_ASC"
  | "UPDATED_DESC";
export type NotebookImage = {
  imageName: string;
  projectId: string;
};
export type NotebookCreate = {
  name: string;
  location: string;
  machineType: string;
  gpuType?: NotebookAcceleratorType;
  image: NotebookImage;
};
