/**
 * The implementation in this file is a frontend-ified version of:
 * https://github.com/MaxKelsen/mk-propel-core-api/blob/main/app/authorization.polar
 * We use the actor, action, resource concept from oso polar: https://docs.osohq.com/node/guides/rbac.html
 * "Oso makes authorization decisions by determining if an actor can perform an action on a resource"
 */

import { ParsedUrlQuery } from "querystring";

export type Role = string | number;

export interface Actor {
  actorIsActive: boolean;
  actorRoles: Role[] | undefined;
}

export interface HasRoleParams extends Actor {
  allowedRoles: Role | Role[];
}

export interface HasPermissionParams<PermissionType extends string, ResourceType extends string> extends Actor {
  action: PermissionType;
  resource: ResourceType;
  permissionMatrix: Record<ResourceType, Record<PermissionType, Role[]>>;
}

interface GetPathForResolvedUrlParams<PathType extends string, ResourceType extends string> {
  resolvedUrl: string;
  params: ParsedUrlQuery | undefined;
  resourceToPageMap: Record<ResourceType, PathType | PathType[]>;
}

export interface CanAccessRouteParams<
  PathType extends string,
  ResourceType extends string,
  PermissionType extends string,
> extends Actor {
  resolvedUrl: string;
  params: ParsedUrlQuery | undefined;
  resourceToPageMap: Record<ResourceType, PathType | PathType[]>;
  permissionMatrix: Record<ResourceType, Record<PermissionType, Role[]>>;
  viewPermission: PermissionType;
}

/**
 * Determines whether the given user is active and has the specified role(s)
 * @returns false if user is NOT active
 * @returns false if user does not have any of the allowedRoles
 * @returns true if user is active AND has one or more of the allowedRoles
 */
export const hasRole = ({ actorIsActive, actorRoles, allowedRoles }: HasRoleParams): boolean => {
  if (!actorRoles || !actorIsActive) {
    return false;
  }

  if (Array.isArray(allowedRoles)) {
    return actorRoles.some(r => allowedRoles.includes(r));
  }

  return actorRoles.includes(allowedRoles);
};

/**
 * Checks whether a given user has the required permission to perform the action on the requested resource based on
 * the permissionMatrix
 */
export const hasPermission = <PermissionType extends string, ResourceType extends string>({
  action,
  actorIsActive,
  actorRoles,
  resource,
  permissionMatrix,
}: HasPermissionParams<PermissionType, ResourceType>): boolean => {
  const allowedRoles = permissionMatrix[resource][action];
  return hasRole({ actorRoles, actorIsActive, allowedRoles });
};

/**
 * Given a resolvedUrl and path params from a ServerSideProps context, finds the path in the
 * resourceToPageMap where the resolvedUrl matches the path after some parsing.
 * @example
 * // returns "/UserManagement/Users/[pageNumber]"
 * getPathForResolvedUrl("/UserManagement/Users/1")
 */
export const getPathForResolvedUrl = <PathType extends string, ResourceType extends string>({
  resolvedUrl,
  resourceToPageMap,
  params,
}: GetPathForResolvedUrlParams<PathType, ResourceType>): PathType | undefined => {
  const allPaths = Object.values(resourceToPageMap).flat(1) as PathType[];

  /**
   * Global Regular Expression to find all path parameters of the form "[param]"
   * The value inside the square bracket is specified as a capture group
   */
  const squareB = /\[([^\]]*)\]/g;

  /**
   * String replacer function which replaces all path params of the form "[param]"
   * with the value of the param of the same name passed in as params
   */
  const resolvedUrlConstructor = (pathname: string, params: ParsedUrlQuery | undefined): string =>
    /**
     * @example
     * given "/UserManagement/Edit/Confirmed/[type]"
     * match = [type] and token = type
     */
    pathname.replace(squareB, (match, token: string): string => {
      if (params) {
        return params[token] as string;
      }
      return pathname;
    });

  const resolvedUrlWithoutQueryParams = resolvedUrl.split(/[?#]/)[0];
  const match = allPaths.find(path => resolvedUrlConstructor(path, params) === resolvedUrlWithoutQueryParams);

  return match;
};

/**
 * Given a path from a resourceToPageMap, finds the resource in the
 * resourceToPageMap.
 * @example
 * // returns "UserManagement"
 * getResourceForPath("/UserManagement/Users/[pageNumber]")
 */
export const getResourceForPath = <PathType extends string, ResourceType extends string>(
  path: PathType,
  resourceToPageMap: Record<ResourceType, PathType | PathType[]>,
): ResourceType => {
  const resourceForRoute = Object.keys(resourceToPageMap).find(resource =>
    resourceToPageMap[resource as ResourceType].includes(path),
  ) as ResourceType | undefined;

  if (!resourceForRoute) {
    throw new Error(`No Resource found for this Path: ${path}. ${resourceToPageMap.toString()}`);
  }

  return resourceForRoute;
};

/**
 * Checks whether the given user has permission to access the requested route by
 * reverse engineering the resolvedUrl
 */
export const canAccessRoute = <PathType extends string, ResourceType extends string, PermissionType extends string>({
  resolvedUrl,
  resourceToPageMap,
  actorIsActive,
  actorRoles,
  params,
  permissionMatrix,
  viewPermission,
}: CanAccessRouteParams<PathType, ResourceType, PermissionType>): boolean => {
  const path = getPathForResolvedUrl({ resolvedUrl, params, resourceToPageMap }) ?? "";

  const resourceForPath = getResourceForPath(path, resourceToPageMap);

  return hasPermission({
    actorIsActive,
    actorRoles,
    action: viewPermission,
    resource: resourceForPath,
    permissionMatrix,
  });
};
