import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RuleGroupType } from "react-querybuilder";
import { initialQuery } from "utils/constants";

export interface DataExplorerSliceState {
  queryId: string | null;
  queryName: string | null;
  query: RuleGroupType;
  isQueryEdited: boolean;
  isAnonymousQuery: boolean;
  isShowQueryEditor: boolean;
}

const initialState: DataExplorerSliceState = {
  queryId: null,
  queryName: null,
  query: initialQuery,
  isQueryEdited: false,
  isAnonymousQuery: true,
  isShowQueryEditor: false,
};

const dataExplorerSlice = createSlice({
  name: "dataExplorer",
  initialState: initialState,
  reducers: {
    setIsQueryEdited: (state, action: PayloadAction<DataExplorerSliceState["isQueryEdited"]>) => {
      state.isQueryEdited = action.payload;
    },
    setQueryId: (state, action: PayloadAction<DataExplorerSliceState["queryId"]>) => {
      state.queryId = action.payload;
    },
    setIsAnonymousQuery: (state, action: PayloadAction<DataExplorerSliceState["isAnonymousQuery"]>) => {
      state.isAnonymousQuery = action.payload;
    },
    setIsShowQueryEditor: (state, action: PayloadAction<DataExplorerSliceState["isShowQueryEditor"]>) => {
      state.isShowQueryEditor = action.payload;
    },
    setQueryName: (state, action: PayloadAction<DataExplorerSliceState["queryName"]>) => {
      state.queryName = action.payload;
    },
    setQueryTree: (state, action: PayloadAction<DataExplorerSliceState["query"]>) => {
      state.query = action.payload;
    },
    resetQuery: () => initialState,
  },
});

export const {
  setQueryName,
  setQueryId,
  setQueryTree,
  resetQuery,
  setIsQueryEdited,
  setIsAnonymousQuery,
  setIsShowQueryEditor,
} = dataExplorerSlice.actions;

export default dataExplorerSlice;
