/**
 * Helper function to stringify querystring parameters.
 *
 * Replaces default `URLSearchParams` behaviour of serializing parameter arrays as a comma-seperated list, which the backend doesn't accept.
 *
 * e.g. `?foo=bar,baz` -> `?foo=bar&foo=baz`
 */
export const paramsSerializer = (params: Record<string, string | string[] | undefined>): string => {
  const serializedParams = new URLSearchParams();

  for (const [paramKey, paramValue] of Object.entries(params)) {
    if (paramValue === undefined) continue;

    if (Array.isArray(paramValue)) {
      paramValue.forEach(value => serializedParams.append(paramKey, value));
    } else {
      serializedParams.append(paramKey, paramValue);
    }
  }

  return serializedParams.toString();
};
