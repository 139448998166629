import { generateRTKErrorLogger, MessageMapper } from "@/utils/core/errorLogger";
import { configureStore } from "@reduxjs/toolkit";
import { StatusCodes } from "http-status-codes";
import { createWrapper } from "next-redux-wrapper";
import authSlice from "store/authSlice";
import dataExplorerSlice from "store/dataExplorer/dataExplorerSlice";
import workbenchSlice from "store/workbench/workbenchSlice";
import { propelApi } from "./api/apiSlice";

type Endpoints = keyof typeof propelApi.endpoints;

const apiErrorMessageMapper: MessageMapper<Endpoints> = (endpointName, status, apiErrorMessage) => {
  switch (endpointName) {
    case "getLoggedInUserApiV1UsersMeGet":
    case "getUserApiV1UsersUserIdGet":
    case "disableUserApiV1UsersDisableUserIdPost":
    case "enableUserApiV1UsersEnableUserIdPost":
      switch (status) {
        case StatusCodes.NOT_FOUND:
        case StatusCodes.UNAUTHORIZED:
          return { message: "User does not exist", unexpected: true };
      }
      break;
    case "listAllUsersApiV1UsersGet":
      switch (status) {
        case StatusCodes.NOT_FOUND:
        case StatusCodes.UNAUTHORIZED:
          return { message: "No users found", unexpected: true };
      }
      break;
    case "updateWorkbenchApiV1WorkbenchesWorkbenchIdPut":
      switch (status) {
        case StatusCodes.CONFLICT:
          return {
            message: "Error! Unable to submit edits.",
            description: apiErrorMessage,
            unexpected: true,
            promptRefresh: true,
          };
        case StatusCodes.IM_A_TEAPOT:
          return {
            type: "warning",
            message: "Workbench Edits Submitted with Errors",
            description:
              "Your workbench edits were submitted with some errors. Please review your data and user inclusions for validity and resubmit. If issues persist, contact our support team for assistance.",
          };
      }
    case "deleteWorkbenchApiV1WorkbenchesWorkbenchIdDelete":
      return {
        message: "Error! Unable to delete workbench.",
        description: apiErrorMessage?.toString(),
        unexpected: true,
      };
    case "createNotebookApiV1WorkbenchesWorkbenchIdNotebooksPost":
      if (StatusCodes.CONFLICT) {
        return { message: "Notebook Creation Error.", description: apiErrorMessage, unexpected: false };
      }

      return {
        message: "Notebook Creation Error.",
        description: "Sorry, there was an error in creating your notebook. Please try again.",
        unexpected: true,
      };
    case "updateCohortApiV1CohortsCohortIdPatch":
      return {
        message: "Cohort Publication Error.",
        description: apiErrorMessage,
        unexpected: true,
      };
    case "createQueryApiV1QueriesPost":
      if (StatusCodes.BAD_REQUEST) {
        return {
          message: "Query Creation Error.",
          description: apiErrorMessage,
          unexpected: true,
        };
      }
    case "getLatestQueryExecutionApiV1QueriesQueryIdExecutionsLatestGet":
      if (StatusCodes.NOT_FOUND && apiErrorMessage === "Query has not been executed yet.") {
        // Skip the error notification when polling the query which is not yet executed.
        return {
          message: null,
          description: apiErrorMessage,
          unexpected: true,
        };
      }
  }
  return { message: "Something went wrong", unexpected: true };
};

/*
 disabling this rule as we want the RTK smarts to take care of defining the types
 so that we can create a type definition based on this function definition
*/
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeStore = () =>
  configureStore({
    reducer: {
      [propelApi.reducerPath]: propelApi.reducer,
      [authSlice.name]: authSlice.reducer,
      [workbenchSlice.name]: workbenchSlice.reducer,
      [dataExplorerSlice.name]: dataExplorerSlice.reducer,
    },
    devTools: true,
    middleware: gDM => gDM().concat([propelApi.middleware, generateRTKErrorLogger(apiErrorMessageMapper)]),
  });

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
