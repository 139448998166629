import { createSlice } from "@reduxjs/toolkit";
import { CohortItem, QueryItem } from "components/Workbench/Cohorts.functions";
import { FormattedUser } from "pages/UserManagement/Users/Users.functions";
import { DocumentUploadSuccess } from "store/api/apiGenerated";

export interface WorkbenchCohort {
  cohorts: CohortItem[];
  totalTags: string[];
}

export interface WorkbenchSliceState {
  name: string;
  purpose: string;
  users: FormattedUser[];
  stepIndex: number;
  documents: DocumentUploadSuccess[];
  isInitialised: boolean;
  version?: number;
  queries: QueryItem[];
  cohorts: WorkbenchCohort;
}

const initialState = {
  name: "",
  purpose: "",
  queries: [],
  cohorts: {
    cohorts: [],
    totalTags: [],
  },
  users: [],
  stepIndex: 0,
  documents: [],
  isInitialised: false,
};

const workbenchSlice = createSlice({
  name: "workbench",
  initialState: initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPurpose: (state, action) => {
      state.purpose = action.payload;
    },
    setQueries: (state, action) => {
      state.queries = action.payload;
    },
    setCohorts: (state, action) => {
      state.cohorts = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
    },
    setInitialEditState: (_state, action) => action.payload,
    resetWorkbench: () => initialState,
  },
});

export const {
  setName,
  setPurpose,
  setCohorts,
  setQueries,
  setUsers,
  setStepIndex,
  setDocuments,
  resetWorkbench,
  setInitialEditState,
} = workbenchSlice.actions;

export default workbenchSlice;
